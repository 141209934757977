<!--
 * @Author: faf
 * @desc: 应收订单数与实收订单数
 * @Date: 2022-07-07 11:21:02
 * @LastEditors: faf
 * @ LastEditTime: 
-->
<template>
  <div class="content-wrapper">
    <div :id="type" class="chart"></div>
  </div>
</template>

<script>
/* eslint-disable */
export default {
  props: {
    type: {
      default: "c1"
    },
    chartParam: {
      default: {}
    }
  },
  watch: {
    chartParam: {
      handler(newVal, oldVal) {
        this.drawGraph();
      },
      deep: true
    }
  },
  data() {
    return {};
  },
  methods: {
    drawGraph() {
      //   let category = ["区域1", "区域2", "区域3", "区域4", "区域5", "区域6"];
      //   // let category = [];
      //   //   let dottedBase = +new Date();
      //   let lineData = [];
      //   let barData = [];
      //   for (let i = 0; i < 6; i++) {
      //     // let date = new Date((dottedBase += 3600 * 24 * 1000));
      //     // category.push(
      //     //   [date.getFullYear(), date.getMonth() + 1, date.getDate()].join('-')
      //     // );
      //     let b = Math.random() * 100;
      //     let d = Math.random() * 200;
      //     barData.push(b);
      //     lineData.push(d + b);
      //   }
      let option = {
        title: {
          // text: this.type == 'c2' ? '' : '单位：个',
          text: this.chartParam.unit,
          color: "#9AA4B3",
          left: "26px",
          textStyle: {
            fontSize: 12,
            fontWeight: "400",
            color: "#9AA4B3"
          }
        },
        grid: {
          left: "30px",
          right: "26px",
          bottom: "10px",
          top: "37px",
          containLabel: true
        },
        backgroundColor: "#fff",
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow"
          },
          formatter: function (val) {
            let tip = `${val[0].axisValue}<br/>`;
            val.forEach((params) => {
              if (params.seriesIndex == 0) {
                tip += `${params.marker} ${params.seriesName}: ${params.value}<br/>`;
              }
              if (params.seriesIndex == 1) {
                tip += `${params.marker} ${params.seriesName}: ${params.value}`;
              }
            });
            return tip;
          }
        },
        legend: {
          itemWidth: 16,
          itemHeight: 5,
          data: this.chartParam.legendData,
          right: "16px",
          top: "1%",
          textStyle: {
            color: "#9AA4B3"
          }
        },
        xAxis: {
          type: "category",
          data: this.chartParam.xData,
          splitLine: { show: false },
          axisTick: { show: false },
          axisLine: {
            show: false,
            lineStyle: {
              color: "#9AA4B3"
            }
          },
          axisLabel: {
            margin: 16
          }
        },
        yAxis: {
          type: "value",
          splitNumber: 5,
          // max: 25000,
          axisTick: {
            show: false
          },
          axisLabel: {
            interval: 2
          },
          splitLine: {
            show: true,
            lineStyle: {
              type: "dashed"
            }
          },
          axisLine: {
            lineStyle: {
              color: "#9AA4B3"
            }
          }
        },
        series: [
          {
            name: this.chartParam.legendData[0],
            type: "line",
            smooth: true, // 缓折线
            showAllSymbol: true,
            symbol: "emptyCircle",
            symbolSize: 6,
            itemStyle: {
              // shadowColor: "red",
              // shadowBlur: 1,
              color: "rgba(64, 217, 176, 0.6600)"
            },
            areaStyle: {
              color: {
                type: "linear",
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                global: false,
                colorStops: [
                  {
                    offset: 0,
                    color: "rgba(64, 217, 176, 0.2600)"
                  },
                  {
                    offset: 0.35,
                    color: "rgba(64, 217, 176, 0.1600)"
                  },
                  {
                    offset: 1,
                    color: "rgba(64, 217, 176,0)"
                  }
                ]
              }
            },
            data: this.chartParam.yData
          },
          {
            name: this.chartParam.legendData[1],
            type: "bar",
            barWidth: 6,
            left: "10px",
            itemStyle: {
              borderRadius: 2,
              color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                { offset: 0, color: "#5A90FF" },
                { offset: 1, color: "#5A90FF" }
              ])
            },
            data: this.chartParam.barData
          },
          {
            name: "line",
            type: "bar",
            barGap: "-100%",
            barWidth: 6,
            itemStyle: {
              color: "#fff"
            },
            z: -2,
            data: this.chartParam.yData
          },
          {
            name: "dotted",
            type: "pictorialBar",
            symbol: "rect",
            itemStyle: {
              color: "rgba(90, 144, 255, 0.3000)"
            },
            symbolRepeat: true,
            symbolSize: [6, 1],
            symbolMargin: 1,
            z: -1,
            data: this.chartParam.yData
          }
        ]
      };
      let chart;
      chart = this.$echarts.init(document.getElementById(this.type));
      chart.setOption(option);
      window.addEventListener("resize", () => {
        chart && chart.resize();
      });
    }
  },
  mounted() {
    this.drawGraph();
    // console.log("init:::", this.chartParam.legendData);
  }
};
</script>

<style scoped lang="stylus" rel="stylesheet/stylus">
.content-wrapper {
  width: 100%;
  height: 266px;
}

.chart {
  width: 100%;
  height: 266px;
}
</style>